import React, { useState } from 'react';
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent
 } from '../ui/card';
 import { Link } from "react-router-dom";
 import { Button } from "@/components/ui/button";
import { useMutation } from '@tanstack/react-query';
import { uploadPDFAction } from '@/actions/pdfActions';
import { LoadingSpinner } from "@/components/ui/loading-spinner";

import { useSubscription } from "@/hooks/useSubscription";
import { UploadProgress } from '../progress/UploadProgress';

// File Upload Component
export function FileUpload() {

  const { isSubscribed } = useSubscription();
  const [file, setFile] = useState<File | null>(null);
  const [recordId, setRecordId] = useState<string | null>(null);

  // React Query useMutation hook to handle file upload
  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: uploadPDFAction,onSuccess: (data) => {
      setRecordId(data.id); 
    },
  });

  // Handle file change event
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  // Handle upload using the mutate function
  const handleUpload = () => {
    if (file) {
      mutateAsync(file);
    }
  };

  return (
    <Card className="mx-auto max-w-sm text-center">
    <CardHeader>
      <CardTitle className="text-2xl">PDF Upload</CardTitle>
      <CardDescription>
      { !isSubscribed && <p className="text-red-500">You must be subscribed to upload a file.</p> }
      { isSubscribed && <Label htmlFor="file">Add a pdf of blood test results to track changes</Label> }
      </CardDescription>
    </CardHeader>
    <CardContent>
      { !isSubscribed && <Link to="/subscriptions"><Button variant="secondary">Go to Subscriptions</Button></Link> }
      { isSubscribed && (
        <> { isPending ? (
            <LoadingSpinner hidden={!isPending} />
          ) : (
            <div className="flex w-full max-w-sm items-center space-x-2">
              <Input id="file" type="file" accept='application/pdf' onChange={handleFileChange} />
              
            { file && (
              <Button
                onClick={handleUpload} 
                className="submit" 
                disabled={isPending}
              > 
              Upload</Button>
            )}
          </div>
        )}
    </>)}
      {recordId && <UploadProgress fileId={recordId}/>}
      {isError && <p className="text-red-500">There was an error uploading the file. {error.message}</p>}
    </CardContent>
    </Card>
  );
}
