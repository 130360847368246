import { useQuery } from "@tanstack/react-query";
import { listUploadedPDFsAction } from "@/actions/pdfActions";

interface UseUploadedFilesResult {
  files: UploadedFile[] | undefined;
  isPending: boolean;
  error: unknown;
  isError: boolean;
}

export const useUploadedFiles = (): UseUploadedFilesResult => {

    const queryFn =  () => listUploadedPDFsAction()
    const { data: files, isPending, error, isError } = useQuery({
    queryKey: ["files"],
    queryFn: queryFn
  });

  return { files, isPending, error, isError }
}

