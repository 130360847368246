import { Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent
 } from '../ui/card';
import { useUploadedFiles } from '@/hooks/useUploadedFiles';

// File Upload Component
export function UploadedFiles() {
    
    const { files, isError } = useUploadedFiles();

  return (
    <Card className="mx-auto max-w-sm text-center">
    <CardHeader>
      <CardTitle className="text-2xl">Uploaded Files</CardTitle>
      <CardDescription>
      </CardDescription>
    </CardHeader>
    <CardContent className='text-sm'>
        {files && files.map((file: UploadedFile) => {
          const date = new Date(file.created);
          return (
            <div key={file.id} className="flex justify-between">
                <p>{file.filename}</p>
                <p>{date.toLocaleString(undefined, { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</p>
                <p>{file.status}</p>
            </div>
          ); })}
      
        {isError && <p className="text-red-500">There was an error uploading the file.</p>}
    </CardContent>
    </Card>
  );
}
