import { Progress } from "@/components/ui/progress"
import { useEffect, useState } from "react";
import { uploadPDFStatusAction } from "@/actions/pdfActions";


interface UploadProgressProps {
    fileId: string;
  }
  
  export const UploadProgress: React.FC<UploadProgressProps> = ({ fileId }) => {
    const [progress, setProgress] = useState(0)
    const [recordStatus, setRecordStatus] = useState("")


    useEffect(() => {
        const fetchStatus = async () => {
            if (!fileId) {
                return;
            }
          const { status}  = await uploadPDFStatusAction(fileId);
            if (progress === 100) {
                return;
            }

          if (status === "Completed") {
                setProgress(100);
                return;
            }
          if (status === "Failed") {
            setProgress(100);
            return;
          }

          if (recordStatus !== status) {
            setRecordStatus(status);
          } else {
            setProgress((prevProgress) => prevProgress + 1);
          }
        };
        

        const timer = setTimeout(fetchStatus, 2000);
        return () => clearTimeout(timer);
      }, [recordStatus, progress, fileId]);


    return (
        <>
        { progress < 100 && <div>
            <p className="text-green-500">Upload Successful</p>
            {recordStatus && <p className="text-sm">{recordStatus}...</p>}
            <Progress value={progress} />
        </div>
        }
        { progress === 100 && <div>
            <p className="text-sm">{recordStatus}</p>
            </div>
        }
        </>
    );
};
