import { CSRFTokenProvider } from "@/auth/CSRFTokenProvider";

export const uploadPDFAction = async (file: File) => {
    const csrftoken = await CSRFTokenProvider.getCSRFToken() || '';
      try {
        console.log('Uploading file...');
    
        const formData = new FormData();
        formData.append('type', 'report');
        formData.append('pdf', file);
    
        const result = await fetch(`${import.meta.env.VITE_HOST}/api/records/`, {
            method: "POST",
            credentials: 'include',
            body: formData,
            headers: {
              'X-CSRFToken': csrftoken,
            },
        });

        if (!result.ok) {
          const error = {
            message: await result.json(),
            status: result.status,
            statusText: result.statusText,
          }
          // @todo - error handling/reporting
          console.log(error)
          throw new Error(error.message);
        }

        return await result.json();
      } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
      }
    };
    
  

  export const uploadPDFStatusAction = async (recordId: string) => {
    const csrftoken = await CSRFTokenProvider.getCSRFToken() || '';
      try {    
        const result = await fetch(`${import.meta.env.VITE_HOST}/api/records/${recordId}/status/`, {
            method: "GET",
            credentials: 'include',
            headers: {
              'X-CSRFToken': csrftoken,
            },
        });

        if (!result.ok) {
          const error = {
            message: await result.json(),
            status: result.status,
            statusText: result.statusText,
          }
          // @todo - error handling/reporting
          console.log(error)
          throw new Error(error.message);
        }

        return await result.json();
      } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
      }
      };

export const listUploadedPDFsAction = async () => {
  const csrftoken = await CSRFTokenProvider.getCSRFToken() || '';
    try {    
      const result = await fetch(`${import.meta.env.VITE_HOST}/api/records/`, {
          method: "GET",
          credentials: 'include',
          headers: {
            'X-CSRFToken': csrftoken,
          },
      });

      if (!result.ok) {
        const error = {
          message: await result.json(),
          status: result.status,
          statusText: result.statusText,
        }
        // @todo - error handling/reporting
        console.log(error)
        throw new Error(error.message);
      }

      return await result.json();
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
    };
          