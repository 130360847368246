import { WithSidebar } from "@/hoc/withSidebar";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import './App.css'
import { Report } from '@/components/report/Report';
import { Sidebar } from "@/components/sidebar/Sidebar";
import { LineChart } from "@/components/report/LineChart";


export function ReportPage() {

  return (
        <Tabs defaultValue="table" className="h-full">
          <WithSidebar
            sidebarHeader={() => (<div className="px-4">
                <TabsList>
                  <TabsTrigger value="table">Table</TabsTrigger>
                  <TabsTrigger value="chart">chart</TabsTrigger>
                </TabsList>
              </div>)}
            sidebarContent={() => <Sidebar/>}>
              <TabsContent value="table" className="h-full">
                <Report/>
              </TabsContent>
              <TabsContent value="chart" className="h-full">
                <LineChart/>
              </TabsContent>
          </WithSidebar>
        </Tabs>
  )
}
